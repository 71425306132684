import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './routes';
import { WithAxios } from '../services/WithAxios';

// import ActivityIndicator from '../components/FeedBack/ActivityIndicator';

// loading component to fallback other components
// const Loading = <ActivityIndicator />;
// to add another router you nedd to put and config all your routes in routes.js

function Routes(): React.ReactElement {
    return (
        <Router>
            <WithAxios>
                <Switch>
                    {routes.map((route, index) => (
                        <route.route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                            role={route.role}
                        />
                    ))}
                </Switch>
            </WithAxios>
        </Router>
    );
}

export default Routes;
