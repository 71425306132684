/* eslint-disable @typescript-eslint/ban-ts-comment */
import { message } from 'antd';
import axios from 'axios';
import { setUserId, setUserProperties } from 'firebase/analytics';
import { DateTime } from 'luxon';
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { analytics } from '../../services/firebase';

import { EnRoles, listAdminRoles } from '../../interfaces/Roles';
import api from '../../services/api';

interface IAuthContext {
    isAuth: boolean;
    logIn: (email: string, password: string) => Promise<void>;
    logOut: () => void;
    data: Data;
    getAssumedToken: (id: number) => Promise<void>;
    resetAssumedToken: () => void;
    isAssuming: boolean;
    isAdmin: boolean;
    isLoading: boolean;
}

type User = {
    id?: number;
    email?: string;
    is_confirmed?: boolean;
    created_at?: DateTime;
    updated_at?: DateTime;
};

export type Data = {
    user: User | null;
    role: Role[] | [];
    error?: { reason: string; date: string };
};

export type Role = {
    type: EnRoles;
};

export const authContext = createContext<IAuthContext>({} as IAuthContext);

const AuthContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [isAssuming, setIsAssuming] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dataInitialState = {
        user: null,
        role: [],
    };

    const [data, setData] = useState<Data>(dataInitialState);

    const logIn = useCallback(async (email: string, password: string) => {
        try {
            setIsLoading(true);
            const response = await api.post('/sessions/login', {
                email,
                password,
            });

            const { user, roles } = response.data;

            if (
                roles.every(
                    (role: { type: string }) =>
                        role.type === EnRoles.trustee ||
                        role.type === EnRoles.customer,
                )
            ) {
                throw new Error('Usuário inválido para o acesso');
            }

            const { token } = response.data.token;

            localStorage.setItem('@NeerHouse:token', token);
            localStorage.setItem('@NeerHouse:user', JSON.stringify(user));
            setData(user.user);
            // tell to analytics that user is logged in
            setUserId(analytics, user.id.toString());
            setUserProperties(analytics, user);

            setIsAuth(true);
        } catch (error: any) {
            if (
                axios.isAxiosError(error) &&
                error?.response?.data?.error === 'bloqued-account'
            ) {
                message.error('Sua conta está bloqueada');
            }
            if (
                error?.response &&
                error?.response.data.message &&
                error?.response.data.message ===
                    'E_INVALID_AUTH_PASSWORD: Password mis-match'
            ) {
                message.error(
                    'Ocorreu um erro, verifique suas credenciais e tente novamente',
                );
            } else {
                if (error?.response && error?.response?.data?.message) {
                    setData((oldData) => {
                        return {
                            ...oldData,
                            error: error?.response?.data,
                        };
                    });
                    return message.error(error?.response?.data?.message);
                }
                if (
                    error.message ===
                    "Cannot read properties of undefined (reading 'status')"
                ) {
                    return message.error('Erro de conexão');
                }
                if (error.message) {
                    return message.error(error.message);
                }
                message.error(
                    'Ocorreu um erro, verifique suas credenciais e tente novamente',
                );
            }
        } finally {
            setIsLoading(false);
        }
    }, []);

    const api_logout = async () => {
        try {
            setIsAuth(false);
            await api.get('/sessions/logout');
            localStorage.removeItem('@NeerHouse:token');
            localStorage.removeItem('@NeerHouse:user');
            resetAssumedToken();
        } catch (error) {
            localStorage.removeItem('@NeerHouse:token');
            localStorage.removeItem('@NeerHouse:user');
            resetAssumedToken();
            setIsAuth(false);
        }
    };

    const logOut = useCallback(async () => {
        await api_logout();
    }, []);

    const getAssumedToken = useCallback(async (id: number) => {
        try {
            const response = await api.get(`sessions/assume-token/${id}`);
            localStorage.setItem(
                '@NeerHouse:assumedToken',
                response.data.token,
            );
            setIsAssuming(true);
        } catch (error: unknown) {
            logOut();
        }
    }, []);

    const resetAssumedToken = () => {
        localStorage.removeItem('@NeerHouse:assumedToken');
        setIsAssuming(false);
    };

    const verifyToken = () => {
        const token = localStorage.getItem('@NeerHouse:token');
        const assumedToken = localStorage.getItem('@NeerHouse:assumedToken');

        if (token && token !== null) {
            setIsAuth(true);
        }
        if (assumedToken && assumedToken !== null) {
            setIsAssuming(true);
        }
    };

    const getUser = useCallback(async () => {
        try {
            setIsLoading(true);
            if (isAuth) {
                const response = await api.get('sessions/get-user', {
                    headers: {
                        Authorization: `Bearer ${
                            localStorage.getItem('@NeerHouse:assumedToken') ??
                            localStorage.getItem('@NeerHouse:token')
                        }`,
                    },
                });

                setIsLoading(false);

                const { user } = response.data;

                if (isAssuming === false && user) {
                    setUserId(analytics, user.id.toString());
                    setUserProperties(analytics, user);
                }
                const role = response.data.roles;

                setData({ user, role });
            } else {
                setIsLoading(false);
                setData(dataInitialState);
            }
        } catch (error) {
            setData(dataInitialState);
            logOut();
        }
    }, [isAuth, isAssuming]);

    useEffect(() => {
        verifyToken();
    }, []);

    useEffect(() => {
        getUser();
    }, [getUser]);

    const isAdmin = React.useMemo(() => {
        if (data?.role)
            return data.role.some((item: Role) =>
                listAdminRoles().includes(item.type),
            );
        return false;
    }, [data]);

    return (
        <authContext.Provider
            value={{
                isAuth,
                logIn,
                isAdmin,
                logOut,
                data,
                getAssumedToken,
                resetAssumedToken,
                isAssuming,
                isLoading,
            }}
        >
            {children}
        </authContext.Provider>
    );
};

function useAuth(): IAuthContext {
    return useContext(authContext);
}

export { useAuth };
export default AuthContext;
