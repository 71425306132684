import React from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import {
    LogoutOutlined,
    ProfileOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import date from '../../../helpers/date';
import {
    PresentationMenu,
    StyledMenu,
    StyledMenuItem,
    StyledMenuDivider,
    StyledMenuItemVersion,
} from './styles';
// contexts
import { useAuth } from '../../../contexts/Auth';
import { getLabelRoles, EnRoles } from '../../../interfaces/Roles';
import pkg from '../../../../package.json';

interface IProps {
    setVisible: (value: boolean) => void;
}

const AvatarDropDownMenu: React.FC<IProps> = ({ setVisible }) => {
    function handleMenuClick() {
        setVisible(false);
    }
    const history = useHistory();

    const { logOut, data } = useAuth();

    const getMenuProps = (pathname: string) => {
        return {
            key: '3',
            onClick: () => {
                if (!data.user) {
                    history.push('/login');
                    return;
                }
                history.push(pathname);
            },
        };
    };

    const handleLogOut = () => {
        logOut();
        setVisible(false);
        history.push('/login');
        message.success('Você saiu do sistema');
    };

    const handleToSettings = () => {
        history.push('/settings');
    };

    const isSuperUser = React.useMemo(() => {
        if (data.role[0].type === EnRoles.sysAdmin) {
            return true;
        }
    }, [data]);

    return (
        <StyledMenu
            subMenuCloseDelay={0.5}
            subMenuOpenDelay={0.5}
            onClick={() => handleMenuClick}
            selectable={false}
        >
            <StyledMenu.ItemGroup key="0">
                <PresentationMenu>
                    <p>
                        <strong>Olá, {data?.user?.email}</strong>
                    </p>
                    <p>
                        <strong>Função:</strong>
                        {getLabelRoles(data?.role?.[0]?.type)}
                    </p>
                </PresentationMenu>
            </StyledMenu.ItemGroup>
            <StyledMenuDivider />
            {/* <StyledMenu.ItemGroup key="1">
                <PresentationMenu>
                    <p>
                        <strong>E-mail:</strong> email@email.com
                    </p>
                    <p>
                        <strong>phone:</strong> --------
                    </p>
                </PresentationMenu>
            </StyledMenu.ItemGroup> */}
            <StyledMenuDivider />
            {/* <StyledMenuItem key="2" icon={<SettingOutlined />}>
                Editar perfil
            </StyledMenuItem>
            <StyledMenuDivider /> */}
            <StyledMenuItem
                icon={<ProfileOutlined />}
                {...getMenuProps('/users/data')}
            >
                Minha Conta
            </StyledMenuItem>
            <StyledMenuDivider />
            {isSuperUser && (
                <StyledMenuItem
                    onClickCapture={handleToSettings}
                    key="9"
                    icon={<SettingOutlined />}
                >
                    Configurações
                </StyledMenuItem>
            )}
            <StyledMenuDivider />
            <StyledMenuItem
                onClickCapture={handleLogOut}
                key="4"
                icon={<LogoutOutlined />}
            >
                Sair
            </StyledMenuItem>
            <StyledMenuItemVersion style={{ color: 'grey' }} key="5">
                Versão: {pkg.version}
            </StyledMenuItemVersion>

            <StyledMenuItemVersion style={{ color: 'grey' }} key="6">
                Publicação: {date(Number(pkg.releasedAt)).format('lll')}
            </StyledMenuItemVersion>
        </StyledMenu>
    );
};

export default AvatarDropDownMenu;
